<template>
  <div>
    <img
      src="@/assets/FH_logo.png"
      alt="Gå til finstadbru hundesport bookingside"
      width="200"
    />
    <h1>Velkommen til Finstadbru Hundesport Booking!</h1>
    <div class="info-texts">
      <p id="info1">
        Her kan du bestille klubbhuset og tid til egentrening på Finstadbru
        Stadion.
      </p>
      <p id="info2">
        Skulle du ønske å leie til kurs, faste treningsgrupper, klubber, stevner
        eller leie område til andre ting. Kontakt oss på mail:
        post@finstadbru.no eller telefon: 95783221
      </p>
      <p>
        For priser, liste over tilgjengelig agility-utstyr, samt regler og
        vilkår for bruk av området finner du på
        <a href="https://www.finstadbru.no/booking/" target="_blank"
          >finstadbru.no/booking</a
        >
      </p>
      <strong>NB! Anbefalt nettleser: Google Chrome</strong>
    </div>
    <button class="btn btn-bookingpage" @click="toBooking">
      Gå til booking
    </button>

    <div id="admin-link">
      <router-link :to="{ path: '/fhbooking/admin' }"
        >Logg inn (Admin)</router-link
      >
    </div>
  </div>
  <!-- Info her -->
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Information",
  setup() {
    const router = useRouter();

    const toBooking = () => {
      router.push({ path: "/fhbooking/booking" });
    };

    return { toBooking };
  },
});
</script>
<style scoped>
.btn-bookingpage {
  border: 2px solid black;
  font-weight: 500;
}
.btn-bookingpage:hover {
  background-color: #61923f;
  color: #4a3019;
  border-color: #4a3019;
}
.info-texts {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .info-texts {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .info-texts {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
}
#admin-link {
  padding-top: 1rem;
  margin-bottom: 3rem;
}
</style>
