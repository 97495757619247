
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Information",
  setup() {
    const router = useRouter();

    const toBooking = () => {
      router.push({ path: "/fhbooking/booking" });
    };

    return { toBooking };
  },
});
